/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import Vue from 'vue/dist/vue.js'
import Axios from 'axios'
import Paths from './paths.js.erb'
import DialogBox from './components/dialog-box'
import AddressList from './components/address-list'
import AreaList from './components/area-list'
import feather from "feather-icons";

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('profile-app') == null) {
    return;
  }

  const profileApp = new Vue({
    el: '#profile-app',
    mixins: [Paths],
    components: { DialogBox, AddressList, AreaList },
    watch: {
      'areaDialog.areaType': {
        handler: function (newValue, oldValue) {
          this.areaDialog.area.area_type = this.areaDialog.areaType;
        },
        deep: true
      },
      'addressDialog.address': {
        handler: function (newValue, oldValue) {
          if (newValue.kind === 'business' && newValue.country_code !== 'HU')
            newValue.country_code = 'HU';
        },
        deep: true
      }
    },
    data() {
      let self = this;
      return {
        countries: [],
        addressDialog: {
          isLoading: false,
          name: null,
          title: null,
          address: null,
          errors: {},
          isVisible: false,
          isUpdate: false,
          path: null,
          onHide: function () {
            self.addressDialog.isVisible = false;
          },
          buttons: [
            {
              title: 'Mentés',
              type: 'btn btn-primary',
              onClick: async function () {
                await self.saveAddress();
                self.$refs['address-list'].reload();
              }
            },
            {
              title: 'Mégsem',
              type: 'btn btn-secondary',
              onClick: function () {
                self.addressDialog.isVisible = false;
              }
            }
          ]
        },
        areaDialog: {
          areaTypes: ["Erdő", "Gyümölcsös", "Szántóföld"],
          areaType: '',
          isLoading: false,
          name: null,
          title: null,
          area: null,
          errors: {},
          isVisible: false,
          isUpdate: false,
          onHide: function () {
            self.areaDialog.isVisible = false;
          },
          path: null,
          productCategories: [],
          buttons: [
            {
              title: 'Mentés',
              type: 'btn btn-primary',
              onClick: async function () {
                await self.saveArea();
                self.$refs['area-list'].reload();
              }
            },
            {
              title: 'Mégsem',
              type: 'btn btn-secondary',
              onClick: function () {
                self.areaDialog.isVisible = false;
              }
            }
          ]
        }
      }
    },
    methods: {
      resetAddressForm() {
        this.addressDialog.address = {
          kind: 'personal',
          title: 'Címem 01',
          name: '',
          line_1: '',
          country_code: 'HU',
          city: '',
          postal_code: '',
          phone_number: '',
          vat_number: '',
        }
        this.addressDialog.errors = {};
      },
      showNewAddressDialog(path) {
        this.addressDialog.isUpdate = false;
        this.addressDialog.path = path;
        this.resetAddressForm();
        this.addressDialog.title = "Új cím hozzáadása"
        this.addressDialog.isVisible = true
      },
      editAddress(path) {
        let self = this;
        self.addressDialog.isUpdate = true;
        self.addressDialog.path = path;
        self.resetAddressForm();
        self.addressDialog.title = "Cím szerkesztése";

        self.addressDialog.isLoading = true;
        Axios.get(path).then(function (response) {
          self.addressDialog.isLoading = false;
          let data = response.data;
          self.addressDialog.address = data.address;
          self.addressDialog.isVisible = true
        });
      },
      async saveAddress() {
        this.addressDialog.isLoading = true;
        this.addressDialog.errors = [];
        let response = null;
        if (this.addressDialog.isUpdate) {
          response = await Axios.patch(this.addressDialog.path, {address: this.addressDialog.address})
        } else {
          response = await Axios.post(this.addressDialog.path, {address: this.addressDialog.address})
        }
        let data = response.data;
        this.addressDialog.isLoading = false;
        if (data.success === true) {
          this.addressDialog.isVisible = false
        } else {
          this.addressDialog.errors = data.errors;
        }
      },
      resetAreaForm() {
        this.areaDialog.areaType = this.areaDialog.areaTypes[0];

        this.areaDialog.area = {
          name: '',
          identifier: '',
          city: '',
          postal_code: '',
          address: '',
          parcel_number: '',
          area_type: this.areaDialog.areaTypes[0],
          area_size: '',
          is_certified: false,
          is_approved: false,
          is_fulfilled: false
        }

        if (typeof this.areaDialog.productCategories != 'undefined') {
          for (let i = 0; i < this.areaDialog.productCategories.length; i++) {
            this.areaDialog.area['product_category_' + this.areaDialog.productCategories[i].id] = 0;
          }
        }
      },
      async loadProductCategories() {
        let response = await Axios.get(this.productCategoriesPath());
        let data = response.data;
        this.areaDialog.productCategories = data.product_categories;
        this.resetAreaForm();
      },
      showNewAreaDialog(path) {
        this.areaDialog.isUpdate = false;
        this.areaDialog.path = path;
        this.resetAreaForm()
        this.areaDialog.title = "Új terület hozzáadása"
        this.areaDialog.isVisible = true
      },
      editArea(path) {
        let self = this;
        self.areaDialog.isUpdate = true;
        self.areaDialog.path = path;
        self.resetAreaForm();
        self.areaDialog.title = "Terület szerkesztése";
        self.areaDialog.areaType = '';

        self.areaDialog.isLoading = true;
        Axios.get(path).then(function (response) {
          self.areaDialog.isLoading = false;
          let data = response.data;
          self.areaDialog.area = data.area;
          self.areaDialog.productCategories = data.area.product_categories;

          if (self.areaDialog.areaTypes.includes(data.area.area_type)) {
            self.areaDialog.areaType = data.area.area_type;
          }

          if (typeof self.areaDialog.productCategories != 'undefined') {
            for (let i = 0; i < self.areaDialog.productCategories.length; i++) {
              self.areaDialog.area['product_category_' + self.areaDialog.productCategories[i].id] = self.areaDialog.productCategories[i].amount;
            }
          }

          self.areaDialog.isVisible = true
        });
      },
      async saveArea() {
        this.areaDialog.isLoading = true;
        this.areaDialog.errors = [];
        let response = null;
        if (this.areaDialog.isUpdate) {
          response = await Axios.patch(this.areaDialog.path, {area: this.areaDialog.area})
        } else {
          response = await Axios.post(this.areaDialog.path, {area: this.areaDialog.area})
        }
        let data = response.data;
        this.areaDialog.isLoading = false;
        if (data.success === true) {
          this.areaDialog.isVisible = false
        } else {
          this.areaDialog.errors = data.errors;
        }
      }
    },
    created() {
      this.resetAddressForm();
      this.resetAreaForm();
      this.loadProductCategories();
    },
    async mounted() {
      const csrfToken = document.querySelector("meta[name=csrf-token]").content
      Axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;

      const response = await Axios.get(this.profileCountriesPath('json'));
      this.countries = response.data.countries;
    },
    updated: function () {
      this.$nextTick(function () {
        feather.replace();
      })
    }
  });
});
