<template>
  <div>
    <div class="eron-shop-container">
      <div class="eron-shop-left-side">
        <div class="eron-shop-section">
          <h3>Termékek</h3>

          <div class="eron-shop-section-contents eron-shop-products">
            <div class="eron-shop-item pt-4 pb-4" v-for="(product, i) in products">
              <div class="thumbnail">
                <img :src="product.medium_image_url ? product.medium_image_url : imagePath('placeholder.svg')" alt="Placeholder">
              </div>
              <div class="eron-shop-item-spacing"></div>
              <div class="eron-shop-item-contents">
                <div class="eron-shop-item-contents-flex">
                  <div class="eron-shop-item-left">
                    <h3>{{ product.name }}</h3>
                    <div class="description">{{ product.description }}</div>
                    <div class="price">{{ product.price | formatPrice }} / db</div>
                  </div>
                  <div class="eron-shop-item-right" v-if="false">
                    <div class="subtotal">{{ product.count * product.price | formatPrice }}</div>
                  </div>
                </div>
                <div class="controls noselect">
                  <a class="remove" @click="decrease(i)" :class="{'disabled': product.count <= 0}">
                    <i data-feather="minus-circle" class="fea icon-md icons"></i>
                  </a>
                  <input class="form-control text-center" type="text" v-model="product.count">
                  <a class="add" @click="increase(i)" :class="{'disabled': product.count >= product.available_amount}">
                    <i data-feather="plus-circle" class="fea icon-md icons"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="shop-error-message" v-if="shopErrors.quantity" ref="shop-error-quantity">A vásárláshoz legalább egy terméket a kosárba kell helyezni</div>
          </div>
        </div>

        <div class="eron-shop-section">
          <h3>Vásárlás módja</h3>

          <div class="eron-shop-section-contents">
            <div class="purchase-modes noselect">
              <div class="purchase-mode" :class="{ 'selected': purchaseMode === 'one_time' }" @click="purchaseMode = 'one_time'">
                <div class="icon">
                  <img :src="imagePath('icon-tickmark.png')">
                </div>
                <div class="label">
                  Egyszeri
                </div>
                <p>Kiválasztva</p>
              </div>
              <div class="purchase-mode disabled" :class="{ 'selected': purchaseMode === 'yearly_subscription' }">
                <div class="icon">
                  <img :src="imagePath('icon-badge.png')">
                </div>
                <div class="label">
                  Megújuló
                </div>
                <p>Hamarosan...</p>
              </div>
            </div>

            <ul class="plain-list mb-5">
              <li class="mb-4">
                <input type="checkbox" id="is_gift" class="me-2 form-check-input" v-model="isGift" @change="handleGiftCheckedChanged()">
                <label for="is_gift" class="form-check-label">Ajándékba adom</label>
              </li>
              <li v-if="isGift" class="mt-2">
                <label for="receiver_name" class="form-label" style="width: 200px;">Ajándékozott neve</label>
                <input type="text" id="receiver_name" class="form-control" v-model="receiverName">
                <div class="shop-error-message" v-if="shopErrors.giftReceiverName" ref="shop-error-gift-receiver-name">Kötelező mező</div>
              </li>
              <li v-if="isGift" class="mt-3">
                <label for="receiver_email" class="form-label" style="width: 200px;">Ajándékozott email címe</label>
                <input type="text" id="receiver_email" class="form-control" v-model="receiverEmail">
                <div class="shop-error-message" v-if="shopErrors.giftReceiverEmail" ref="shop-error-gift-receiver-email">Kötelező mező</div>
              </li>
            </ul>
          </div>
        </div>

        <div class="eron-shop-section" v-if="isSignedIn">
          <h3>Számlázási cím</h3>

          <div class="eron-shop-section-contents">
            <div class="address-row mt-2">
              <div class="address-input-container" v-show="addresses.length > 0">
                <select class="form-select form-control" aria-label="Cím választása" v-model="addressId">
                  <option v-for="address in addresses" :value="address.id">{{ address.display_name }}</option>
                </select>
              </div>
              <div class="address-button-container">
                <a href="javascript:void(0)" @click="showNewAddressDialog" class="btn btn-primary">
                  <i data-feather="plus-circle" class="fea icon-sm icons"></i> Új cím
                </a>
              </div>
            </div>
            <div class="shop-error-message" v-if="shopErrors.address" ref="shop-error-address">Kötelező mező</div>
          </div>
        </div>

        <div class="eron-shop-section" v-if="isSignedIn">
          <h3>Adattovábbítási nyilatkozat</h3>

          <div class="eron-shop-section-contents">
            <div class="row">
              <div class="col-12 mt-2">
                <p>
                  <a href="https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf" target="_blank">
                    <img class="simple-pay-logo" src="<%= image_path('simplepay.png') %>" :srcset="imagePath('simplepay.png') + ' 1x, ' + imagePath('simplepay@2x.png') + ' 2x'" title="SimplePay - Online bankkártyás fizetés" alt="SimplePay vásárlói tájékoztató">
                  </a>
                </p>
              </div>
              <div class="col-12 mt-4">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="privacyCheck" v-model="privacyChecked">
                  <label class="form-check-label" for="privacyCheck" style="font-weight: normal; text-align: justify">
                    Tudomásul veszem, hogy az Eron Forestry Kft. (1054 Budapest, Honvéd utca 8. 1. emelet 2.) adatkezelő által az {{ host }} felhasználói adatbázisában tárolt alábbi személyes adataim átadásra kerülnek az OTP Mobil Kft., mint adatfeldolgozó részére. Az adatkezelő által továbbított adatok köre az alábbi: e-mail cím és számlázási cím.
                    Az adatfeldolgozó által végzett adatfeldolgozási tevékenység jellege és célja a SimplePay Adatkezelési tájékoztatóban, az alábbi linken tekinthető meg: <a href="http://simplepay.hu/vasarlo-aff" target="_blank" class="link">http://simplepay.hu/vasarlo-aff</a>.
                  </label>
                  <div class="shop-error-message" v-if="shopErrors.privacy" ref="shop-error-privacy">Kötelező mező</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="eron-shop-right-side">
        <div class="eron-shop-section eron-shop-summary">
          <h3>Kosár</h3>

          <div class="eron-shop-section-contents">
            <table class="table mb-0 table-center">
              <tbody>

              <tr v-for="product in products" v-show="product.count > 0">
                <td class="small p-0 pt-3 pb-3">
                  {{ product.name }}<br>
                  {{ product.description }}
                </td>
                <td class="text-end small p-0 pt-3 pb-3">
                  {{ product.count * product.price | formatPrice }}
                  <span v-if="purchaseMode === 'yearly_subscription' "> / év</span>
                </td>
              </tr>

              </tbody>
              <tfoot>
              <tr>
                <td class="p-0 pt-3 pb-3 fw-bold">Összesen:</td>
                <td class="p-0 pt-3 pb-3 fw-bold text-end">{{ sum | formatPrice }}<span v-if="purchaseMode === 'yearly_subscription' "> / év</span></td>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="shop-submit row mb-5" v-if="!isSignedIn">
      <p>A számlázási és fizetési adatok megadására a következő képernyőn lesz lehetősége; kérjük regisztráljon felhasználói fiókot a visszaigazolás és számlázás érdekében!</p>
      <form method="POST" :action="shopSaveCartAndSignInPath()" @submit="handleFormSubmit">
        <input type="hidden" name="authenticity_token" :value="authenticityToken">
        <input type="hidden" name="data" :value="JSON.stringify(formData)">
        <input type="submit" class="btn btn-primary" value="Kérjük, érdeklődjön e-mailen!" disabled>
      </form>
    </div>

    <div class="shop-submit row mt-5 mb-5" v-if="isSignedIn">
      <form method="POST" :action="shopSubmitPath()" @submit="handleFormSubmit">
        <input type="hidden" name="authenticity_token" :value="authenticityToken">
        <input type="hidden" name="data" :value="JSON.stringify(formData)">
        <input type="submit" class="btn btn-primary" value="Kérjük, érdeklődjön e-mailen!" disabled>
      </form>
    </div>

    <!-- Modal Content Start -->
    <div v-if="isSignedIn" class="modal fade show" id="address-form" tabindex="-1" aria-labelledby="address-form-title" aria-modal="true" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content rounded shadow border-0">
          <div class="modal-header border-bottom">
            <h5 class="modal-title" id="address-form-title">Új cím hozzáadása</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Mégsem"></button>
          </div>
          <div class="modal-body">
            <div class="bg-white p-3 rounded box-shadow">

              <form>

                <div class="row mt-2">

                  <div class="col-12 col-md-6">
                    <div class="form-group position-relative mb-3">
                      <input type="radio" name="address[kind]" id="address_kind_personal" value="personal" v-model="newAddress.kind" class="me-2 form-check-input">
                      <label for="address_kind_personal" class="form-label">Magánszemély</label>
                      <ul class="text-danger mt-2" v-if="typeof newAddressErrors.kind != 'undefined'">
                        <li v-for="error in newAddressErrors.kind">{{ error }}</li>
                      </ul>
                    </div>
                  </div><!--end col-->

                  <div class="col-12 col-md-6">
                    <div class="form-group position-relative mb-3">
                      <input type="radio" name="address[kind]" id="address_kind_business" value="business" v-model="newAddress.kind" class="me-2 form-check-input">
                      <label for="address_kind_business" class="form-label">Jogi személy</label>
                    </div>
                  </div><!--end col-->

                  <div class="col-12 col-md-12">
                    <div class="form-group position-relative mb-3">
                      <label class="form-label" for="address_name">Vásárló neve <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i data-feather="user" class="fea icon-sm icons"></i>
                        <input type="text" class="form-control ps-5" placeholder="Vásárló neve" name="address[name]" required="required" id="address_name" v-model="newAddress.name">
                      </div>
                      <ul class="text-danger mt-2" v-if="typeof newAddressErrors.name != 'undefined'">
                        <li v-for="error in newAddressErrors.name">{{ error }}</li>
                      </ul>
                    </div>
                  </div><!--end col-->

                  <div class="col-24 col-md-12">
                    <div class="form-group position-relative mb-3">
                      <label class="form-label" for="address_country_code">Ország <span class="text-danger">*</span></label>
                      <select class="form-select form-control" id="address_country_code" required="required" aria-label="Ország választása" :disabled="newAddress.kind === 'business'" v-model="newAddress.country_code">
                        <option v-for="country in countries" :value="country.code">{{ country.name }}</option>
                      </select>
                      <ul class="text-danger mt-2" v-if="typeof newAddressErrors.country_code != 'undefined'">
                        <li v-for="error in newAddressErrors.country_code">{{ error }}</li>
                      </ul>
                    </div>
                  </div><!--end col-->

                  <div class="col-12 col-md-6">
                    <div class="form-group position-relative mb-3">
                      <label class="form-label" for="address_postal_code">Irányítószám <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i data-feather="tag" class="fea icon-sm icons"></i>
                        <input type="text" class="form-control ps-5" placeholder="1001" name="address[postal_code]" required="required" id="address_postal_code" v-model="newAddress.postal_code">
                      </div>
                      <ul class="text-danger mt-2" v-if="typeof newAddressErrors.postal_code != 'undefined'">
                        <li v-for="error in newAddressErrors.postal_code">{{ error }}</li>
                      </ul>
                    </div>
                  </div><!--end col-->

                  <div class="col-12 col-md-6">
                    <div class="form-group position-relative mb-3">
                      <label class="form-label" for="address_city">Város <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i data-feather="home" class="fea icon-sm icons"></i>
                        <input type="text" class="form-control ps-5" placeholder="Budapest" name="address[city]" required="required" id="address_city" v-model="newAddress.city">
                      </div>
                      <ul class="text-danger mt-2" v-if="typeof newAddressErrors.city != 'undefined'">
                        <li v-for="error in newAddressErrors.city">{{ error }}</li>
                      </ul>
                    </div>
                  </div><!--end col-->

                  <div class="col-24 col-md-12">
                    <div class="form-group position-relative mb-3">
                      <label class="form-label" for="address_line_1">Utca, házszám <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i data-feather="mail" class="fea icon-sm icons"></i>
                        <input type="text" class="form-control ps-5" placeholder="Utca, házszám" name="address[line_1]" required="required" id="address_line_1" v-model="newAddress.line_1">
                      </div>
                      <ul class="text-danger mt-2" v-if="typeof newAddressErrors.line_1 != 'undefined'">
                        <li v-for="error in newAddressErrors.line_1">{{ error }}</li>
                      </ul>
                    </div>
                  </div><!--end col-->

                  <div class="col-12 col-md-6">
                    <div class="form-group position-relative mb-3">
                      <label class="form-label" for="address_phone_number">Telefonszám</label>
                      <div class="form-icon position-relative">
                        <i data-feather="phone" class="fea icon-sm icons"></i>
                        <input type="text" class="form-control ps-5" placeholder="+36 30 123 4567" name="address[phone_number]" id="address_phone_number" v-model="newAddress.phone_number">
                      </div>
                      <ul class="text-danger mt-2" v-if="typeof newAddressErrors.phone_number != 'undefined'">
                        <li v-for="error in newAddressErrors.phone_number">{{ error }}</li>
                      </ul>
                    </div>
                  </div><!--end col-->

                  <div class="col-12 col-md-6" v-if="newAddress.kind === 'business'">
                    <div class="form-group position-relative mb-3">
                      <label class="form-label" for="address_vat_number">Adószám <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i data-feather="briefcase" class="fea icon-sm icons"></i>
                        <input type="text" class="form-control ps-5" placeholder="Adószám" name="address[vat_number]" required="required" maxlength="13" id="address_vat_number" v-model="newAddress.vat_number">
                      </div>
                      <ul class="text-danger mt-2" v-if="typeof newAddressErrors.vat_number != 'undefined'">
                        <li v-for="error in newAddressErrors.vat_number">{{ error }}</li>
                      </ul>
                    </div>
                  </div><!--end col-->

                </div><!--end row-->

              </form>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="cancelNewAddress()">Mégsem</button>
            <button type="button" class="btn btn-primary" @click="saveNewAddress()">Mentés</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Content End -->
  </div>
</template>

<script>
import Paths from "../paths.js.erb";
import Axios from "axios";
import feather from 'feather-icons';
import Vue from "vue";

export default {
  name: 'shop',
  mixins: [Paths],
  props: {
    isSignedIn: Boolean
  },
  data() {
    return {
      isCartEmpty: true,
      privacyChecked: false,
      products: [],
      addresses: [],
      addressId: null,
      countries: [],
      newAddress: {},
      newAddressErrors: {},
      _version: 0,
      authenticityToken: '',
      host: '',
      isGift: false,
      receiverName: null,
      receiverEmail: null,
      purchaseMode: 'one_time',
      shopErrors: {
        quantity: false,
        giftReceiverName: false,
        giftReceiverEmail: false,
        address: false,
        privacy: false
      }
    }
  },
  computed: {
    sum() {
      this._version;
      let sum = 0;
      for (let i = 0; i < this.products.length; i++) {
        sum += this.products[i].count * this.products[i].price
      }
      return sum;
    },
    formData() {
      return {
        products: this.products,
        address_id: this.addressId,
        is_gift: this.isGift,
        gift_receiver_name: this.receiverName,
        gift_receiver_email: this.receiverEmail,
        purchase_mode: this.purchaseMode
      }
    }
  },
  watch: {
    products: {
      handler: function (newValue, oldValue) {
        this.validateCounts();
        this._version++;
      },
      deep: true
    },
    sum: {
      handler: function (newValue, oldValue) {
        if (newValue > 0)
          this.shopErrors.quantity = false;
      }
    },
    receiverName: {
      handler: function (newValue, oldValue) {
        if (newValue != null && newValue !== '')
          this.shopErrors.giftReceiverName = false;
      }
    },
    receiverEmail: {
      handler: function (newValue, oldValue) {
        if (newValue != null && newValue !== '')
          this.shopErrors.giftReceiverEmail = false;
      }
    },
    addressId: {
      handler: function (newValue, oldValue) {
        if (newValue != null)
          this.shopErrors.address = false;
      }
    },
    privacyChecked: {
      handler: function (newValue, oldValue) {
        if (newValue !== false)
          this.shopErrors.privacy = false;
      }
    },
    newAddress: {
      handler: function (newValue, oldValue) {
        if (newValue.kind === 'business' && newValue.country_code !== 'HU')
          newValue.country_code = 'HU';
      },
      deep: true
    }
  },
  filters: {
    formatPrice: function (value) {
      if (value == null || value === '')
        return '';

      return Number(value).toLocaleString('hu') + ' HUF';
    }
  },
  methods: {
    validateCounts() {
      for (let i = 0; i < this.products.length; i++) {
        if (isNaN(this.products[i].count)) {
          this.products[i].count = 0
        } else if (this.products[i].count < 0) {
          this.products[i].count = 0
        } else if (this.products[i].count > this.products[i].available_amount) {
          this.products[i].count = this.products[i].available_amount
        }
      }
    },
    increase(index) {
      this.products[index].count++;
    },
    decrease(index) {
      this.products[index].count--;
    },
    async loadAddresses() {
      const response = await Axios.get(this.shopAddressesPath('json'));

      this.addresses = response.data.addresses;
      if (this.addressId === null) {
        this.addressId = response.data.addresses[response.data.addresses.length - 1].id
      }
    },
    showNewAddressDialog() {
      this.resetNewAddressForm();
      $('#address-form').modal('show');
    },
    cancelNewAddress() {
      $('#address-form').modal('hide');
    },
    async saveNewAddress() {
      const response = await Axios.post(this.shopSaveAddressPath(), {address: this.newAddress});

      if (response.data.success === true) {
        this.resetNewAddressForm();
        $('#address-form').modal('hide');
        await this.loadAddresses();
      } else {
        this.newAddressErrors = response.data.errors;
      }
    },
    resetNewAddressForm() {
      this.newAddress = {
        kind: 'personal',
        title: 'Címem 01',
        name: '',
        line_1: '',
        country_code: 'HU',
        city: '',
        postal_code: '',
        phone_number: '',
        vat_number: '',
      }
      this.newAddressErrors = {};
    },
    handleGiftCheckedChanged() {
      if (this.isGift)
        this.purchaseMode = 'one_time';
    },
    handleFormSubmit(e) {
      // temporarily disable form submission
      if (true) {
        e.preventDefault();
        return;
      }

      this.shopErrors.quantity = false;
      this.shopErrors.giftReceiverName = false;
      this.shopErrors.giftReceiverEmail = false;
      this.shopErrors.address = false;
      this.shopErrors.privacy = false;

      let hadError = false;

      if (this.sum === 0) {
        hadError = true;
        this.shopErrors.quantity = true;
      }

      if (this.isGift) {
        if (this.receiverName == null || this.receiverName === '') {
          hadError = true;
          this.shopErrors.giftReceiverName = true;
        }

        if (this.receiverEmail == null || this.receiverEmail === '') {
          hadError = true;
          this.shopErrors.giftReceiverEmail = true;
        }
      }

      if (this.isSignedIn) {
        if (this.addressId == null) {
          hadError = true;
          this.shopErrors.address = true;
        }

        if (this.privacyChecked === false) {
          hadError = true;
          this.shopErrors.privacy = true;
        }
      }

      if (hadError) {
        e.preventDefault();

        // scroll to the first error
        Vue.nextTick(() => {
          const propsInOrder = ['quantity', 'giftReceiverName', 'giftReceiverEmail', 'address', 'privacy'];
          const refName = ['shop-error-quantity', 'shop-error-gift-receiver-name', 'shop-error-gift-receiver-email', 'shop-error-address', 'shop-error-privacy'];

          for (let i = 0; i < propsInOrder.length; i++) {
            const prop = propsInOrder[i];

            if (this.shopErrors[prop]) {
              const element = this.$refs[refName[i]];

              if (element != null)
                element.scrollIntoView({ behavior: 'smooth', block: 'center' });

              break;
            }
          }
        });
      }
    }
  },
  async mounted() {
    this.host = window.location.host;

    const csrfToken = document.querySelector("meta[name=csrf-token]").content
    this.authenticityToken = csrfToken;
    Axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;

    const response = await Axios.get(this.shopPath('json'));

    this.products = response.data.products;
    this.countries = response.data.countries;
    this.purchaseMode = response.data.purchase_mode;
    this.receiverName = response.data.gift_receiver_name;
    this.receiverEmail = response.data.gift_receiver_email;
    this.isGift = response.data.is_gift;
    this.addressId = response.data.address_id;
    feather.replace();

    await this.loadAddresses();
    this.resetNewAddressForm();
  },
  updated: function () {
    this.$nextTick(function () {
      feather.replace();
    })
  }
}
</script>

<style scoped lang="scss">
.plain-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.eron-shop-container {
  display: flex;
  flex-direction: row;
}

.eron-shop-left-side {
  flex: 1;
}

.eron-shop-right-side .eron-shop-summary {
  width: 32.5rem;
  margin-left: 3rem;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 14rem;
}

.eron-shop-section {
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.09);
  background: #ffffff;
  padding: 0;
  overflow: auto;
  border-bottom: 0.4rem solid #61a601;
}

.eron-shop-section + .eron-shop-section {
  margin-top: 3.6rem;
}

.eron-shop-section h3 {
  font-family: ClanProWide, sans-serif !important;
  font-weight: 500 !important;
  font-size: 1.6rem !important;
  color: #080808;
  text-transform: uppercase;
  margin: 0;
  padding: 1.7rem 4rem 1.7rem 4rem;
}

.eron-shop-section-contents {
  margin: 0 4rem 2.8rem 4rem;
}

.eron-shop-products {
  flex: 1;
}

.eron-shop-products .eron-shop-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.eron-shop-products .eron-shop-item + .eron-shop-item {
  border-top: 1px solid #CCC;
}

.eron-shop-products .eron-shop-item .thumbnail img {
  width: 120px;
  height: 120px;
  border: 1px solid #CCC;
  object-fit: contain;
  object-position: center;
}

.eron-shop-products .eron-shop-item .eron-shop-item-spacing {
  max-width: 3rem;
  min-width: 1.2rem;
  flex: 1;
}

.eron-shop-products .eron-shop-item .eron-shop-item-contents {

}

.eron-shop-products .eron-shop-item .eron-shop-item-contents .eron-shop-item-contents-flex {
  display: flex;
  flex-direction: row;
}

.eron-shop-products .eron-shop-item .eron-shop-item-contents .eron-shop-item-contents-flex .eron-shop-item-left {
  flex: 1;
}

.eron-shop-products .eron-shop-item .eron-shop-item-contents .eron-shop-item-contents-flex .eron-shop-item-left h3 {
  font-size: 18px !important;
  font-weight: normal;
  display: block;
  margin: 0;
  padding: 0;
}

.eron-shop-products .eron-shop-item .eron-shop-item-contents .eron-shop-item-contents-flex .eron-shop-item-left .description {
  margin: 0.75rem 0 0.75rem 0;
  padding: 0;
}

.eron-shop-products .eron-shop-item .eron-shop-item-contents .eron-shop-item-contents-flex .eron-shop-item-left .price {
  margin: 0.75rem 0 0.75rem 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.5)
}

.eron-shop-products .eron-shop-item .eron-shop-item-contents-flex .eron-shop-item-right {
  margin-left: 2rem;
}

.eron-shop-products .eron-shop-item .eron-shop-item-contents-flex .eron-shop-item-right .subtotal {
  color: rgba(0, 0, 0, 0.5)
}

.eron-shop-products .eron-shop-item .eron-shop-item-contents .controls {
  margin-top: 0.75rem;
}

.eron-shop-products .eron-shop-item .eron-shop-item-contents .controls a {
  color: #61a601;
  cursor: pointer;
}

.eron-shop-products .eron-shop-item .eron-shop-item-contents .controls a:active, .eron-shop-products .eron-shop-item .eron-shop-item-contents .controls a:hover {
  color: #7bc711;
}

.eron-shop-products .eron-shop-item .eron-shop-item-contents .controls .disabled {
  opacity: 0.2;
}

.eron-shop-products .eron-shop-item .eron-shop-item-contents .controls .form-control {
  width: 8rem;
  display: inline;
  margin: 0;
}

.eron-shop-products .shop-error-message {
  text-align: center;
}

.eron-shop-summary tfoot td {
  border-bottom-width: 0;
}

.purchase-modes {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 1rem 4rem 1rem;
}

.purchase-modes > div {
  margin-right: 4.5rem;
  cursor: pointer;
}

.purchase-modes > div:last-child {
  margin-right: 2.6rem;
}

.purchase-modes .purchase-mode {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 12rem;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
}

.purchase-modes .purchase-mode.disabled {
  opacity: 0.5;
  cursor: default;
}

.purchase-modes .purchase-mode .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.8rem;
}

.purchase-modes .purchase-mode .icon img {
  height: 1.6rem;
}

.purchase-modes .purchase-mode .label {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 1.4rem 0 rgba(0, 0, 0, 0.09);
  background: #ffffff;
}

.purchase-modes .purchase-mode .label {
  height: 6.4rem;
  line-height: 6.4rem;
  font-size: 2.3rem;
  font-weight: bold;
  text-align: center;
  vertical-align: center;
  color: #141b28;
}

.purchase-modes .purchase-mode .label .count {
  height: 2.6rem;
  line-height: 2.6rem;
  font-size: 1.2rem;
  font-weight: normal;
  text-align: center;
  vertical-align: center;
  background: #141b28;
  color: #ffffff;
}

.purchase-modes .purchase-mode p {
  margin: 1.2rem 0 0 0;
  color: #141b28;
  font-size: 1.2rem;
  text-align: center;
  text-transform: uppercase;
}

.purchase-modes .purchase-mode.selected:after {
  content: '';
  position: absolute;
  top: 1rem;
  right: -1rem;
  bottom: -1rem;
  left: -1rem;
  border: 0.2rem solid #61a601;
}

.address-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.address-input-container {
  flex: 1;
  margin-right: 1.6rem;
}

.address-button-container {

}

.simple-pay-logo {
  position: relative;
  left: -36px;
}

.shop-submit {
  margin-top: 4rem !important;
  margin-right: 35.5rem !important;
  margin-bottom: 6.6rem !important;
  text-align: center;
}

.shop-submit p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.shop-submit .btn {
  min-width: 15rem;
  padding: 1.2rem 2.8rem;
  border-radius: 1rem !important;
  font-size: 1.6rem;
}

.checkout-image {
  max-width: 100%;
  margin-right: 35.5rem !important;
  margin-top: 4rem;
  margin-bottom: 6.6rem;
  text-align: center;
}

.checkout-image img {
  max-height: 20rem;
}

.shop-error-message {
  margin-top: 0.6rem;
  font-size: 1.2rem;
  color: #e43f52;
}

select.form-control {
  white-space: nowrap;
  padding-right: 2.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 1010px) {
  .address-row {
    flex-direction: column;
  }

  .address-input-container {
    margin-right: 0;
  }

  .address-button-container {
    margin-top: 1.6rem;
  }
}

@media screen and (max-width: 800px) {
  .eron-shop-container {
    flex-direction: column;
  }

  .eron-shop-right-side .eron-shop-summary {
    margin-left: 0;
    margin-top: 3.6rem;
    width: 100%;
  }

  .eron-shop-products .eron-shop-item {
    flex-direction: column;
    align-items: center;
  }

  .eron-shop-products .eron-shop-item .eron-shop-item-contents {
    margin-left: 0;
    margin-top: 2rem;
  }

  .eron-shop-products .eron-shop-item .eron-shop-item-contents .eron-shop-item-contents-flex {
    flex-direction: column;
    text-align: center;
  }

  .purchase-modes {
    flex-direction: column;
  }

  .purchase-modes > div {
    margin-right: 0;
    margin-bottom: 4rem;
  }

  .purchase-modes > div:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }

  .checkout-image {
    margin-top: 2.8rem;
    margin-right: 0 !important;
    margin-bottom: 3.6rem;
  }

  .checkout-image img {
    max-width: 100%;
  }

  .shop-submit {
    margin-right: 0 !important;
  }
}

</style>