<template>
  <div class="accordion" id="accordion-areas">
    <div class="accordion-item rounded shadow mb-4" v-for="(area, index) in areas">
      <h2 class="accordion-header position-relative" :id="'areas-heading-' + index">
        <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#areas-collapse-' + index"
                aria-expanded="false" :aria-controls="'areas-collapse-' + index">
          {{ area.name }}
        </button>
        <div class="eron-accordion-custom-button-container">
          <span v-if="!isProfile && !area.is_approved" class="eron-in-progress-label badge rounded-md bg-soft-warning me-4">Jóváhagyandó</span>
          <a v-if="!isProfile || (isProfile && area.is_approved)" @click="editArea(area.path)" href="#" class="text-primary fw-bold me-2"><i class="uil uil-edit text-primary h5 mb-0"></i></a>
          <a v-if="!isProfile || (isProfile && area.is_approved)" :href="area.path" data-confirm="Biztosan törölni szeretné a címet?" data-method="delete" class="text-primary fw-bold"><i class="uil uil-trash text-primary h5 mb-0"></i></a>
          <span v-if="isProfile && !area.is_approved" class="eron-in-progress-label badge rounded-md bg-soft-warning">Jóváhagyás alatt</span>
          <a v-if="isProfile && !area.is_inquiry_enabled" :href="area.inquiry_path" data-method="post" class="text-primary ms-2 text-decoration-underline" style="font-size: 16px;">Érdeklődés</a>
        </div>
      </h2>
      <div :id="'areas-collapse-' + index" class="accordion-collapse border-0 collapse" :aria-labelledby="'areas-heading-' + index"
           data-bs-parent="#accordion-areas">
        <div class="accordion-body eron-justify text-muted bg-white">
          <ul class="eron-address-display">
            <li><label>Azonosító</label>{{ area.identifier }}</li>
            <li><label>Település</label>{{ area.city }}</li>
            <li><label>Irányítószám</label>{{ area.postal_code }}</li>
            <li><label>Cím</label>{{ area.address }}</li>
            <li><label>Helyrajzi szám</label>{{ area.parcel_number }}</li>
            <li><label>Terület jellege</label>{{ area.area_type }}</li>
            <li><label>Terület mérete</label>{{ area.area_size }} m2</li>
            <li><label>Teljesítés</label>{{ area.is_fulfilled ? 'Teljesítve' : 'Folyamatban' }}</li>
            <li><label>Minősített telepítés</label>{{ area.is_certified ? 'Igen' : 'Nem' }}</li>
            <li v-for="product_category in area.product_categories"><label>{{ product_category.name }}</label>{{ product_category.amount }} db</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";

export default {
  name: "area-list",
  props: {
    path: String,
    editArea: Function,
    isProfile: Boolean
  },
  data() {
    return {
      areas: []
    }
  },
  methods: {
    async reload() {
      const response = await Axios.get(this.path);
      this.areas = response.data.areas;
    }
  },
  mounted() {
    this.reload();
  }
}
</script>