<template>
  <div class="accordion" id="accordion-addresses">
    <div class="accordion-item rounded shadow mb-4" v-for="(address, index) in addresses">
      <h2 class="accordion-header position-relative" id="addresses-heading-<%= index %>">
        <button class="accordion-button border-0 bg-light collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#addresses-collapse-' + index"
                aria-expanded="false" :aria-controls="'addresses-collapse-' + index">
          {{ address.display_name }}
        </button>
        <div class="eron-accordion-custom-button-container">
          <a href="#" @click="editAddress(address.path)" class="text-primary fw-bold me-2"><i class="uil uil-edit text-primary h5 mb-0"></i></a>
          <a :href="address.path" data-confirm="Biztosan törölni szeretné a címet?" data-method="delete" class="text-primary fw-bold"><i class="uil uil-trash text-primary h5 mb-0"></i></a>
        </div>
      </h2>
      <div :id="'addresses-collapse-' + index" class="accordion-collapse border-0 collapse" :aria-labelledby="'addresses-heading-' + index"
           data-bs-parent="#accordion-addresses">
        <div class="accordion-body eron-justify text-muted bg-white">
          <ul class="eron-address-display">
            <li><label>Vásárló neve</label>{{ address.name }}</li>
            <li><label>Utca, házszám</label>{{ address.line_1 }}</li>
            <li><label>Ország</label>{{ address.country }}</li>
            <li><label>Város</label>{{ address.city }}</li>
            <li><label>Irányítószám</label>{{ address.postal_code }}</li>
            <li><label>Telefonszám</label>{{ address.phone_number }}</li>
            <li v-if="address.kind === 'business'"><label>Adószám</label>{{ address.vat_number }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";

export default {
  name: "address-list",
  props: {
    path: String,
    editAddress: Function
  },
  data() {
    return {
      addresses: []
    }
  },
  methods: {
    async reload() {
      const response = await Axios.get(this.path);
      this.addresses = response.data.addresses;
    }
  },
  mounted() {
    this.reload();
  }
}
</script>