// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "./common/backtotop"
import "./common/cookiealert"
import "./common/enable-force-dark-fix"
import "./common/feather"
import "./common/tobii"
import "./shop"
import "./profile"
import "./calculator"

// Turbolinks.start()
ActiveStorage.start()
Rails.start()
