<template>
  <div>
    <section class="calculator-header" ref="header">
      <h2>Válassz az alábbi témakörökből:</h2>
    </section>

    <section class="calculator-content" ref="section0">
      <div class="left">
        <div class="section" :class="{ 'active': activeSection === 0 }">
          <h3 @click="selectSection(0)">
            Egyéni utazás
            <span class="section-status-icon">
              <img :src="activeSection === 0 ? imagePath('angle-up-solid.svg') : imagePath('angle-down-solid.svg')">
            </span>
          </h3>
          <ul class="questions">
            <li>
              <p>Milyen közlekedési eszközzel utaztál?</p>
              <select v-model="uniqueTrip.vehicleEmission">
                <option :value="null">Válassz egyet...</option>
                <option v-for="vehicle in uniqueTrip.vehicles" :value="vehicle.value">{{ vehicle.title }}</option>
              </select>
            </li>
            <li v-if="uniqueTrip.vehicleEmission >= 0">
              <p>Hány km az úti cél?</p>
              <input type="number" min="0" v-model="uniqueTrip.distance" v-clear-on-focus>
            </li>
            <li>
              <p>Milyen régióba utaztál?</p>
              <select v-model="uniqueTrip.selectedRegion">
                <option :value="null">Válassz egyet...</option>
                <option v-for="region in uniqueTrip.regions" :value="region">{{ region.title }}</option>
              </select>
            </li>
            <li v-if="uniqueTrip.selectedRegion !== null && uniqueTrip.selectedRegion.id !== 'cruise'">
              <p>Hol szálltál meg?</p>
              <select v-model="uniqueTrip.accommodationEmission">
                <option :value="null">Válassz egyet...</option>
                <option v-for="accommodation in uniqueTrip.selectedRegion.accommodations" :value="accommodation.value">{{ accommodation.title }}</option>
              </select>
            </li>
            <li v-if="uniqueTrip.selectedRegion !== null && uniqueTrip.selectedRegion.id !== 'cruise'">
              <p>Hány éjszakát szálltál meg?</p>
              <input type="number" min="0" v-model="uniqueTrip.days" v-clear-on-focus>
            </li>
            <li v-if="uniqueTrip.selectedRegion !== null && uniqueTrip.selectedRegion.id !== 'cruise'">
              <p>Milyen szabadidős tevékenységeket csináltál?</p>
              <ul class="attributes">
                <li>
                  <label>Búvárkodás</label>
                  <input type="number" min="0" v-model="uniqueTrip.activities.diving" class="small" v-clear-on-focus>
                  <span class="wide">merülés</span>
                </li>
                <li>
                  <label>Síelés</label>
                  <input type="number" min="0" v-model="uniqueTrip.activities.skiing" class="small" v-clear-on-focus>
                  <span class="wide">nap</span>
                </li>
                <li>
                  <label>Golfozás</label>
                  <input type="number" min="0" v-model="uniqueTrip.activities.golf" class="small" v-clear-on-focus>
                  <span class="wide">kör</span>
                </li>
                <li>
                  <label>Vitorlázás</label>
                  <input type="number" min="0" v-model="uniqueTrip.activities.sailing" class="small" v-clear-on-focus>
                  <span class="wide">nap</span>
                </li>
                <li>
                  <label>Hajós kirándulás</label>
                  <input type="number" min="0" v-model="uniqueTrip.activities.boatTrip" class="small" v-clear-on-focus>
                  <span class="wide">túra</span>
                </li>
                <li>
                  <label>Jet Ski</label>
                  <input type="number" min="0" v-model="uniqueTrip.activities.jetSki" class="small" v-clear-on-focus>
                  <span class="wide">óra</span>
                </li>
              </ul>
            </li>
          </ul>
          <div class="tip">
            <div class="sign">Tipp!</div>
            <div class="text">
              <ul>
                <li>Már vannak környezettudatos szállások, érdemes figyelni.</li>
                <li>A szállodai törülközőket újra használata vendégéjszakára lebontva 1,75 kg CO2e megtakarítást eredményez.</li>
                <li>Utazás alatt is gyűjthetünk szelektíven. Egy alumínium doboz újra hasznosítása megtakarítja az új előállításához szükséges energia 90% -át.</li>
                <li>Légkondi, vagy nem légkondi? A légkondicionáló berendezések kétóránként több, mint 1 kg CO2 kibocsátást okoznak.</li>
              </ul>
            </div>
          </div>
        </div>


        <div class="section" :class="{ 'active': activeSection === 1 }" ref="section1">
          <h3 @click="selectSection(1)">
            Háztartás
            <span class="section-status-icon">
              <img :src="activeSection === 1 ? imagePath('angle-up-solid.svg') : imagePath('angle-down-solid.svg')">
            </span>
          </h3>
          <ul class="questions">
            <li>
              <p>Számold ki háztartásod havi energiaigényének karbonlábnyomát!</p>
              <ul class="attributes">
                <li>
                  <label>Villanyfogyasztás</label>
                  <input type="number" min="0" v-model="home.electricity" class="small" v-clear-on-focus>
                  <span>kWh</span>
                </li>
                <li>
                  <label>A villanyfogyasztás hány %-át fedezi napelem?</label>
                  <input type="number" min="0" v-model="home.solar" class="small" v-clear-on-focus>
                  <span>%</span>
                </li>
                <li>
                  <label>Gázfogyasztás</label>
                  <input type="number" min="0" v-model="home.gas" class="small" v-clear-on-focus>
                  <span>m3</span>
                </li>
                <li>
                  <label>Távhő</label>
                  <input type="number" min="0" v-model="home.heating" class="small" v-clear-on-focus>
                  <span>GJ</span>
                </li>
                <li>
                  <label>Vízfogyasztás</label>
                  <input type="number" min="0" v-model="home.water" class="small" v-clear-on-focus>
                  <span>m3</span>
                </li>
              </ul>
            </li>
          </ul>
          <div class="tip">
            <div class="sign">Tipp!</div>
            <div class="text">
              <ul>
                <li>Izzóid energiatakarékosra történő cseréjével, nemcsak 400 kg CO2e kibocsátást takaríthatsz meg, de 10x kevesebbszer kell égőt cserélni.</li>
                <li>Ha időzítjük a fűtésünket és éjszaka, vagy amikor nem tartózkodunk otthon, 3 °C-kal alacsonyabban tarjuk lakásunk hőmérsékletét, évente 440 kg CO2e kibocsátását spóroljuk meg.</li>
                <li>Nyílászáróink szigetelésével évente 140 kg CO2e kibocsátást kerülhetünk el.</li>
                <li>A ruhák levegőn történő szárításával mosásonként kb. 2 kg CO2e kibocsátást takaríthatunk meg.</li>
                <li><b>Mindezek természetesen a fizetendő havi számláinkban is megjelennek megtakarításként.</b></li>
              </ul>
            </div>
          </div>
        </div>

        <div class="section" :class="{ 'active': activeSection === 2 }" ref="section2">
          <h3 @click="selectSection(2)">
            Havi közlekedés
            <span class="section-status-icon">
              <img :src="activeSection === 2 ? imagePath('angle-up-solid.svg') : imagePath('angle-down-solid.svg')">
            </span>
          </h3>
          <ul class="questions">
            <template v-for="(vehicle, vi) in commute.usedVehicles">
              <li>
                <p>
                  Milyen közlekedési eszközzel utaztál?
                  <img class="delete-button" @click="removeVehicle(vi)" :src="imagePath('trash-can-solid.svg')">
                </p>
                <select v-model="vehicle.vehicleEmission">
                  <option :value="null">Válassz egyet...</option>
                  <option v-for="v in commute.vehicles" :value="v.value">{{ v.title }}</option>
                </select>
              </li>
              <li>
                <p>Havonta megtett út (km)</p>
                <input type="number" min="0" v-model="vehicle.distance" v-clear-on-focus>
              </li>
            </template>
            <li>
              <button @click="addVehicle()" class="button green add-more">További közlekedési eszköz</button>
            </li>
          </ul>
          <div class="tip">
            <div class="sign">Tipp!</div>
            <div class="text">
              <ul>
                <li>Az alacsony viszkozitású motorolajok 2,5%-kal csökkenthetik autónk üzemanyag-felhasználását.</li>
                <li>Ha rövidebb távon a sétát választjuk, akár 75%-kal lecsökkenthetjük a károsanyag-kibocsátásodat.</li>
                <li>A rendszeres guminyomás ellenőrzése segít az optimális fogyasztás elérésében, mellyel pénztárcánkat és a környezetet is kíméljük.</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="section" :class="{ 'active': activeSection === 3 }" ref="section3">
          <h3 @click="selectSection(3)">
            Egyéni bevásárlás
            <span class="section-status-icon">
              <img :src="activeSection === 3 ? imagePath('angle-up-solid.svg') : imagePath('angle-down-solid.svg')">
            </span>
          </h3>
          <ul class="questions">
            <li>
              <p>Mivel mentél el bevásárolni?</p>
              <select v-model="shopping.vehicleEmission">
                <option :value="null">Válassz egyet...</option>
                <option v-for="vehicle in shopping.vehicles" :value="vehicle.value">{{ vehicle.title }}</option>
              </select>
            </li>
            <li v-if="shopping.vehicleEmission >= 0">
              <p>Milyen messze van a bolt? (km)</p>
              <input type="number" min="0" v-model="shopping.distance" v-clear-on-focus>
            </li>

            <li>
              <p>Vettél húskészítményt?</p>
              <ul class="attributes">
                <li>
                  <label>Marha</label>
                  <select v-model="shopping.beef" class="small">
                    <option v-for="v in pickable(0, 15, 0.5)" :value="v">{{ v }}</option>
                  </select>
                  <span class="wide">Kg</span>
                </li>
                <li>
                  <label>Sertés</label>
                  <select v-model="shopping.pork" class="small">
                    <option v-for="v in pickable(0, 15, 0.5)" :value="v">{{ v }}</option>
                  </select>
                  <span class="wide">Kg</span>
                </li>
                <li>
                  <label>Bárány</label>
                  <select v-model="shopping.lamb" class="small">
                    <option v-for="v in pickable(0, 15, 0.5)" :value="v">{{ v }}</option>
                  </select>
                  <span class="wide">Kg</span>
                </li>
                <li>
                  <label>Szárnyas</label>
                  <select v-model="shopping.poultry" class="small">
                    <option v-for="v in pickable(0, 15, 0.5)" :value="v">{{ v }}</option>
                  </select>
                  <span class="wide">Kg</span>
                </li>
                <li>
                  <label>Hal</label>
                  <select v-model="shopping.fish" class="small">
                    <option v-for="v in pickable(0, 15, 0.5)" :value="v">{{ v }}</option>
                  </select>
                  <span class="wide">Kg</span>
                </li>
              </ul>
            </li>

            <li>
              <p>Vettél tejterméket?</p>
              <ul class="attributes">
                <li>
                  <label>Tejtermék</label>
                  <select v-model="shopping.dairy" class="small">
                    <option v-for="v in pickable(0, 15, 0.5)" :value="v">{{ v }}</option>
                  </select>
                  <span class="wide">liter/kg</span>
                </li>
              </ul>
            </li>

            <li>
              <p>Vettél tojást?</p>
              <ul class="attributes">
                <li>
                  <label>Tojás</label>
                  <select v-model="shopping.egg" class="small">
                    <option v-for="v in pickable(0, 15, 1)" :value="v">{{ v }}</option>
                  </select>
                  <span class="wide">db</span>
                </li>
              </ul>
            </li>

            <li>
              <p>Vettél zöldséget, gyümölcsöt?</p>
              <ul class="attributes">
                <li>
                  <label>Zöldség, gyümölcs</label>
                  <select v-model="shopping.vegetables" class="small">
                    <option v-for="v in pickable(0, 15, 1)" :value="v">{{ v }}</option>
                  </select>
                  <span class="wide">kg</span>
                </li>
              </ul>
            </li>

            <li>
              <p>Vettél alkoholt?</p>
              <ul class="attributes">
                <li>
                  <label>Alkohol</label>
                  <select v-model="shopping.alcohol" class="small">
                    <option v-for="v in pickable(0, 15, 0.5)" :value="v">{{ v }}</option>
                  </select>
                  <span class="wide">liter</span>
                </li>
              </ul>
            </li>

            <li>
              <p>Vettél tisztítószereket?</p>
              <ul class="attributes">
                <li>
                  <label>Tisztítószer</label>
                  <select v-model="shopping.detergent" class="small">
                    <option v-for="v in pickable(0, 15, 1)" :value="v">{{ v }}</option>
                  </select>
                  <span class="wide">liter/kg</span>
                </li>
              </ul>
            </li>
          </ul>
          <div class="tip">
            <div class="sign">Tipp!</div>
            <div class="text">
              <ul>
                <li>A szezonális hazai zöldségek és gyümölcsök előtérbe helyezésével elkerülhető az áruszállítással kapcsolatos jelentősebb kibocsátás.</li>
                <li>A déli gyümölcsök közül a banán CO2e kibocsátása pusztán 80g darabonként, mert nem kell hozzájuk üvegház, sokáig eláll, általában hajón kerül hozzánk és kicsi a csomagolásigénye.</li>
                <li>Fél kg marhahús előállításához majdnem 20 ezer liter víz szükséges.</li>
                <li>Egy húsmentes hétfő, 400 kg CO2e kibocsátás-csökkentést jelenthet évente.</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="extra">
          <div class="send-to-myself" v-if="isCalculatorSaved !== null">
            <span class="label" v-if="isCalculatorSaved === true">Az eredményt elküldtük e-mailben a megadott címre.</span>
            <span class="label" v-else>Sajnos hiba történt az eredmény küldése közben. Kérjük próbáld újra</span>
          </div>

          <div class="send-to-myself" v-if="isSendToMyselfVisible && isCalculatorSaved !== true">
            <input type="text" v-model="sendToMyself.email" placeholder="E-mail cím" name="email" ref="email">
            <div id="recaptcha-container"></div>
            <button class="button" :disabled="emission <= 0 || sendToMyself.email === null || sendToMyself.email.length <= 0 || sendToMyself.recaptcha === null || sendToMyself.recaptcha.length <= 0" @click="saveCalculatorResults()">Küldés</button>
          </div>

          <h2>Ellentételezd most!</h2>
          <div class="extra-content">
            <div class="offset-adjustment" :class="{ 'selected': offsetAmount === 1.0 }" @click="offsetAmount = 1.0">
              <div class="icon">
                <img :src="imagePath('icon-tickmark.png')">
              </div>
              <div class="values">
                <span class="percentage">100%</span>
                <span class="count">{{ calculateItemCount(this.emission, 1.0) }} fa</span>
              </div>
              <p>Ennyi a Te lábnyomod</p>
            </div>
            <div class="offset-adjustment" :class="{ 'selected': offsetAmount === 1.2 }" @click="offsetAmount = 1.2" v-if="is120PercentVisible">
              <div class="icon">
                <img :src="imagePath('icon-badge.png')">
              </div>
              <div class="values">
                <span class="percentage">120%</span>
                <span class="count">{{ calculateItemCount(this.emission, 1.2) }} fa</span>
              </div>
              <p>Ha biztosra mennél</p>
            </div>
            <div class="offset-adjustment" :class="{ 'selected': offsetAmount === 2.0 }" @click="offsetAmount = 2.0">
              <div class="icon">
                <img :src="imagePath('icon-crown.png')">
              </div>
              <div class="values">
                <span class="percentage">200%</span>
                <span class="count">{{ calculateItemCount(this.emission, 2.0) }} fa</span>
              </div>
              <p>Dupla? Maradhat?</p>
            </div>
            <div class="offset-adjustment custom" :class="{ 'selected': offsetAmount !== 1.0 && offsetAmount !== 1.2 && offsetAmount !== 2.0 }" @click="offsetAmount = null; focusCustomInput()">
              <div class="icon">
                <img :src="imagePath('icon-pencil.png')">
              </div>
              <div class="values">
                <span class="percentage" v-if="offsetAmount !== 1.0 && offsetAmount !== 1.2 && offsetAmount !== 2.0">
                  <input class="custom-percentage" type="text" min="0" max="999" maxlength="3" pattern="[0-9]{1-3}" placeholder="Egyéni" v-model="customOffsetAmount" ref="customPercentageInput" oninput="this.value = this.value.replace(/[^0-9.]/g, '')">
                  <span class="custom-percentage-sign" ref="customPercentageSign">%</span>
                </span>
                <span class="percentage" v-else>Egyéni</span>
                <span class="count">{{ calculateItemCount(this.emission, this.customOffsetAmount / 100.0) }} fa</span>
              </div>
              <p>Lehet több is!</p>
            </div>
            <div class="buttons">
              <div class="submit">
                <form method="POST" :action="buildCartPath()">
                  <input type="hidden" name="authenticity_token" :value="authenticityToken">
                  <input type="hidden" name="emission" :value="adjustedEmissionForCart">
                  <input type="submit" class="button green" value="Vásárlás" :disabled="adjustedItemCount <= 0">
                </form>
              </div>
            </div>
          </div>
        </div>

        <div class="disclaimer">
          <div class="separator"></div>
          <p>A CO2e, másnéven szén-dioxid ekvivalens, az összes üvegházhatású gáz globális felmelegedésre gyakorolt hatását egységesíti szén-dioxid egyenértékű kibocsátásra, mint közös egységre vetítve.</p>
          <p><sup style="font-size: 1.5rem; vertical-align: baseline; display: inline-block; margin-right: 0.25rem;">*</sup>A fenti kalkuláció kizárólag a károsanyag-kibocsátási adatok és egy átlagos szénelnyelési kapacitás alapján
          számolt famennyiség becslésére alkalmazható. Az ERON Forestry nyilvánosan elérhető, folyamatosan frissített
          adatbázisokból dolgozik. Mindent elkövetünk, hogy az adatok pontosságát biztosítsuk, de az itt használt
          emissziós faktorok és szénmegkötési adatok átlagértékeken és feltételezéseken alapulnak. Az ERON Forestry és
          a kalkulátor készítője nem felelős a becslések helyességért, vagy a látogató által a megbecsült mennyiségek
          alapján kezdeményezett további cselekedetekért. A weboldalunkon megjelenő összes információ a teljesség és a
          hitelesség igénye, valamint bármiféle garanciavállalás nélkül értendő.</p>
        </div>
      </div>
      <div class="right">
        <div class="box-container">
          <div class="box">
            <h3>Ellentételezz velünk!</h3>
            <p>Tevékenységed  karbonlábnyoma:</p>
            <span class="value">{{ emission }} KG CO2e</span>
          </div>
          <button class="button send-result-button" @click="showSendToMyself()" :disabled="emission <= 0">Elküldöm magamnak</button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Vue from "vue";
import Paths from "../paths.js.erb";
import Axios from "axios";
import DialogBox from "./dialog-box";
//import feather from 'feather-icons';

export default {
  name: 'simple-calculator',
  components: {
    DialogBox
  },
  directives: {
    'clear-on-focus': {
      inserted: function (el) {
        $(el).on('focus', () => {
          if ($(el).val() === '0')
            $(el).val('');
          else
            $(el).select();
        })

        $(el).on('blur', () => {
          if ($(el).val() === '') {
            $(el).val('0');
          }
        })
      }
    }
  },
  mixins: [Paths],
  data() {
    return {
      authenticityToken: '',
      recaptchaSiteKey: '',
      offsetAmount: 1.0,
      customOffsetAmount: null,
      productOffsetAmount: null,
      activeSection: null,
      sections: [],
      isSendToMyselfVisible: false,
      isCalculatorSaved: null,

      sendToMyself: {
        email: null,
        recaptcha: null
      },

      uniqueTrip: {
        vehicles: [
          { title: 'Benzines autó', value: 0.197333333 },
          { title: 'Diesel autó', value: 0.175875 },
          { title: 'Gáz autó (LPG)', value: 0.124666667 },
          { title: 'Gyalog', value: -1 },
          { title: 'Elektromos autó', value: 0.04194 },
          { title: 'Nagymotor', value: 0.10775 },
          { title: 'Busz (utas kilométer)', value: 0.00815 },
          { title: 'Vonat (utas kilométer)', value: 0.045 },
          { title: 'Repülő (utas kilométer)', value: 0.115 }
        ],
        vehicleEmission: null,
        distance: 0,
        regions: [
          {
            id: 'hungary',
            title: 'Belföld (Magyarország)',
            accommodations: [
              { title: 'panzió, külváros/vidék', value: 8.049521729 },
              { title: '3 csillag, középkategória', value: 9.211456773 },
              { title: '4 csillag,  konferencia', value: 15.0324461 },
              { title: '5 csillag, wellness', value: 25.13886561 }
            ]
          },
          {
            id: 'africa',
            title: 'Afrika',
            accommodations: [
              { title: 'panzió, külváros/vidék', value: 15.09 },
              { title: '3 csillag, középkategória', value: 17.75717762 },
              { title: '4 csillag,  konferencia', value: 39.66997773 },
              { title: '5 csillag, wellness', value: 37.50129173 }
            ]
          },
          {
            id: 'australia',
            title: 'Ausztrália és Óceánia',
            accommodations: [
              { title: 'panzió, külváros/vidék', value: 18.33038882 },
              { title: '3 csillag, középkategória', value: 15.74186349 },
              { title: '4 csillag,  konferencia', value: 26.00492077 },
              { title: '5 csillag, wellness', value: 44.75708473 }
            ]
          },
          {
            id: 'europe',
            title: 'Európa',
            accommodations: [
              { title: 'panzió, külváros/vidék', value: 8.049521729 },
              { title: '3 csillag, középkategória', value: 9.211456773 },
              { title: '4 csillag,  konferencia', value: 15.0324461 },
              { title: '5 csillag, wellness', value: 25.13886561 }
            ]
          },
          {
            id: 'exotic',
            title: 'Egzotikus sziget (Maldív; Indonézia, Karib szigetek, Antarktisz, stb)',
            accommodations: [
              { title: 'panzió, külváros/vidék', value: 42.97672335 },
              { title: '3 csillag, középkategória', value: 96.3594299 },
              { title: '4 csillag,  konferencia', value: 115.63 },
              { title: '5 csillag, wellness', value: 129.4426197 }
            ]
          },
          {
            id: 'middle-east',
            title: 'Közel-Kelet',
            accommodations: [
              { title: 'panzió, külváros/vidék', value: 38.36311195 },
              { title: '3 csillag, középkategória', value: 42.45562515 },
              { title: '4 csillag,  konferencia', value: 66.36245338 },
              { title: '5 csillag, wellness', value: 110.151338 }
            ]
          },
          {
            id: 'latin-america',
            title: 'Latin-Amerika',
            accommodations: [
              { title: 'panzió, külváros/vidék', value: 7.923094982 },
              { title: '3 csillag, középkategória', value: 8.331523894 },
              { title: '4 csillag,  konferencia', value: 15.56930339 },
              { title: '5 csillag, wellness', value: 39.39743248 }
            ]
          },
          {
            id: 'asia',
            title: 'Ázsia',
            accommodations: [
              { title: 'panzió, külváros/vidék', value: 22.00736834 },
              { title: '3 csillag, középkategória', value: 24.92603696 },
              { title: '4 csillag,  konferencia', value: 40.09061323 },
              { title: '5 csillag, wellness', value: 52.64814034 }
            ]
          },
          {
            id: 'north-america',
            title: 'Észak-Amerika',
            accommodations: [
              { title: 'panzió, külváros/vidék', value: 12.09142205 },
              { title: '3 csillag, középkategória', value: 13.21524351 },
              { title: '4 csillag,  konferencia', value: 19.71064011 },
              { title: '5 csillag, wellness', value: 32.08883364 }
            ]
          },
          {
            id: 'cruise',
            title: 'Óceánjáró',
            accommodations: [
            ]
          }
        ],
        selectedRegion: null,
        accommodationEmission: null,
        days: 0,
        activities: {
          diving: 0,
          skiing: 0,
          golf: 0,
          sailing: 0,
          boatTrip: 0,
          jetSki: 0
        }
      },

      home: {
        electricity: 0,
        solar: 0,
        gas: 0,
        heating: 0,
        water: 0
      },

      commute: {
        vehicles: [
          { title: 'Benzines autó', value: 0.197333333 },
          { title: 'Diesel autó', value: 0.175875 },
          { title: 'Gáz autó (LPG)', value: 0.124666667 },
          { title: 'Kismotor', value: 0.072 },
          { title: 'Gyalog', value: -1 },
          { title: 'Elektromos autó', value: 0.04194 },
          { title: 'Nagymotor', value: 0.10775 },
          { title: 'Busz (utas kilométer)', value: 0.00815 },
          { title: 'Villamos (utas kilométer)', value: 0.03 },
          { title: 'Vonat (utas kilométer)', value: 0.045 },
          { title: 'Metró (utas kilométer)', value: 0.00003 },
        ],
        usedVehicles: [{
          vehicleEmission: null,
          distance: 0
        }]
      },

      shopping: {
        vehicles: [
          { title: 'Benzines autó', value: 0.197333333 },
          { title: 'Diesel autó', value: 0.175875 },
          { title: 'Gáz autó (LPG)', value: 0.124666667 },
          { title: 'Kismotor', value: 0.072 },
          { title: 'Gyalog', value: -1 },
          { title: 'Elektromos autó', value: 0.04194 },
          { title: 'Nagymotor', value: 0.10775 },
          { title: 'Busz (utas kilométer)', value: 0.00815 },
          { title: 'Villamos (utas kilométer)', value: 0.03 },
          { title: 'Vonat (utas kilométer)', value: 0.045 },
          { title: 'Metró (utas kilométer)', value: 0.00003 },
        ],
        vehicleEmission: null,
        distance: 0,
        beef: 0,
        pork: 0,
        lamb: 0,
        poultry: 0,
        fish: 0,
        dairy: 0,
        egg: 0,
        vegetables: 0,
        alcohol: 0,
        detergent: 0
      }

    }
  },
  computed: {
    uniqueTripEmission() {
      let value = 0;
      value += (this.uniqueTrip.vehicleEmission !== null ? Math.max(0.0, this.uniqueTrip.vehicleEmission) : 0.0) * this.uniqueTrip.distance * 2.0;

      if (this.uniqueTrip.selectedRegion !== null) {
        if (this.uniqueTrip.selectedRegion.id === 'cruise') {
          value += 820.0;
        } else {
          value += (this.uniqueTrip.accommodationEmission !== null ? this.uniqueTrip.accommodationEmission : 0.0) * this.uniqueTrip.days;
          value += this.uniqueTrip.activities.diving * 18.6;
          value += this.uniqueTrip.activities.skiing * 20.0;
          value += this.uniqueTrip.activities.golf * 17.263;
          value += this.uniqueTrip.activities.sailing * 0.8;
          value += this.uniqueTrip.activities.boatTrip * 44.0;
          value += this.uniqueTrip.activities.jetSki * 20.0;
        }
      }
      return value;
    },

    homeEmission() {
      let value = 0;
      value += this.home.electricity * (1.0 - (this.home.solar / 100.0)) * 0.5675;
      value += this.home.gas * 1.85;
      value += this.home.heating * 57.6;
      value += this.home.water * 0.298;
      return value;
    },

    commuteEmission() {
      let value = 0;
      for (let i = 0; i < this.commute.usedVehicles.length; i++) {
        let vehicle = this.commute.usedVehicles[i];
        if (vehicle.vehicleEmission !== null) {
          value += Math.max(0.0, vehicle.vehicleEmission) * vehicle.distance;
        }
      }
      return value;
    },

    shoppingEmission() {
      let value = 0;

      value += (this.shopping.vehicleEmission !== null ? Math.max(0.0, this.shopping.vehicleEmission) : 0.0) * this.shopping.distance * 2.0;

      value += this.shopping.beef * 27.0;
      value += this.shopping.pork * 12.1;
      value += this.shopping.lamb * 39.2;
      value += this.shopping.poultry * 8.9;
      value += this.shopping.fish * 4.5;

      value += this.shopping.dairy * 15.12;
      value += this.shopping.egg * 3.13;
      value += this.shopping.vegetables * 1.55;
      value += this.shopping.alcohol * 1.83;
      value += this.shopping.detergent * 0.7;

      return value;
    },

    emission() {
      let value = 0;
      value += this.uniqueTripEmission;
      value += this.homeEmission;
      value += this.commuteEmission;
      value += this.shoppingEmission;
      return Math.round(value);
    },

    finalOffsetAmount() {
      if (this.offsetAmount != null)
        return this.offsetAmount;
      else if (!isNaN(this.customOffsetAmount))
        return this.customOffsetAmount / 100.0;
      else
        return 0.0;
    },

    adjustedItemCount() {
      return this.calculateItemCount(this.emission, this.finalOffsetAmount);
    },

    adjustedEmissionForCart() {
      return this.adjustedItemCount * this.productOffsetAmount;
    },

    is120PercentVisible() {
      return this.calculateItemCount(this.emission, 1.0) >= 5;
    }
  },
  methods: {
    recaptchaCallback(response) {
      this.sendToMyself.recaptcha = response;
    },
    showSendToMyself() {
      this.isCalculatorSaved = null;
      this.isSendToMyselfVisible = true;
      this.sendToMyself.recaptcha = null;
      Vue.nextTick(() => {
        this.$refs.email.focus();
        grecaptcha.render('recaptcha-container', {
          'sitekey': this.recaptchaSiteKey,
          'callback': this.recaptchaCallback
        });
      });
    },
    async saveCalculatorResults() {
      let response = await Axios.post(this.saveCalculatorResultsPath(), {
        email: this.sendToMyself.email,
        recaptcha: this.sendToMyself.recaptcha,
        results: {
          uniqueTripEmission: Math.round(this.uniqueTripEmission),
          homeEmission: Math.round(this.homeEmission),
          commuteEmission: Math.round(this.commuteEmission),
          shoppingEmission: Math.round(this.shoppingEmission),
          overallEmission: this.emission
        }
      });
      let data = response.data;
      if (typeof data.success !== 'undefined' && data.success === true) {
        this.isCalculatorSaved = true;
      } else {
        this.isCalculatorSaved = false;
      }
    },
    pickable(min, max, increment) {
      let result = []
      for (let i = min; i <= max; i+= increment) {
        result.push(i);
      }
      return result;
    },
    addVehicle() {
      this.commute.usedVehicles.push({
        vehicleEmission: null,
        distance: 0
      });
    },
    removeVehicle(index) {
      this.commute.usedVehicles.splice(index, 1);
    },
    async loadShopConfiguration() {
      let response = await Axios.get(this.shopConfigurationPath());
      let data = response.data;
      this.productOffsetAmount = data.product_offset_amount;
    },
    selectSection(section) {
      this.activeSection = section === this.activeSection ? null : section;
      Vue.nextTick(() => {
        let s = this.$refs['section' + section];
        $("html, body").animate({ scrollTop: $(s).position().top - 180.0 }, 300);
        //$(window).scrollTop($(s).position().top - 160.0);
      });
    },
    calculateItemCount(forEmission, multiplier) {
      if (isNaN(forEmission) || isNaN(multiplier))
        return 0;

      const baseItemCount = Math.ceil(forEmission / this.productOffsetAmount);

      return Math.round(baseItemCount * multiplier)
    },
    focusCustomInput() {
      Vue.nextTick(() => {
        this.$refs.customPercentageInput.focus();
      });
    }
  },
  async mounted() {
    const csrfToken = document.querySelector("meta[name=csrf-token]").content
    this.recaptchaSiteKey = document.querySelector("meta[name=recaptchaSiteKey]").content
    this.authenticityToken = csrfToken;
    Axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;

    this.loadShopConfiguration();

    // scroll to the calculator on mobile devices
    if (window.matchMedia('(max-width: 800px)').matches) {
      // wait for images to load as well
      $(window).on('load', () => {
        const header = $(this.$refs.header);
        const topNav = $('#top-navigation');
        window.scrollTo({top: header.offset().top - topNav.height() - 48, behavior: 'smooth'});
      });
    }

    //feather.replace();
  },
  updated: function () {
    this.$nextTick(function () {
      //feather.replace();
    })
  },
  watch: {
    is120PercentVisible: function(val) {
      if (val) {
        if (this.offsetAmount != null && this.offsetAmount < 1.2)
          this.offsetAmount = 1.2;
      } else {
        if (this.offsetAmount === 1.2)
          this.offsetAmount = 1.0;
      }
    }
  }
}
</script>

<style scoped>

.calculator-header {
  margin: 0 auto 2rem auto;
  max-width: 108rem;
}

.calculator-header h2 {
  font-size: 2.1rem;
  font-weight: normal;
  color: #767676;
  margin: 8.4rem 0 3.6rem 0;
}

.calculator-content {
  margin: 0 auto 0 auto;
  display: flex;
  flex-direction: row;
  max-width: 112rem;
}

.calculator-content .left {
  flex-grow: 1;
  flex-shrink: 1;
}

.calculator-content .left .section {
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.09);
  background: #ffffff;
  padding: 0;
  margin-bottom: 3.6rem;
  overflow: hidden;
  height: 5.5rem;
}

.calculator-content .left .section.active {
  height: auto;
}

.calculator-content .left .section h3 {
  font-size: 1.6rem;
  font-weight: bold;
  color: #080808;
  text-transform: uppercase;
  margin: 0;
  padding: 1.7rem 4rem 1.7rem 4rem;
}

.calculator-content .left .section h3 .section-status-icon {
  float: right;
  width: 1.6rem;
}

.calculator-content .left .section h3 .section-status-icon img {
  width: 1.6rem;
  vertical-align: middle;
}

.calculator-content .left .section .questions {
  list-style: none;
  margin: 0 4rem 1rem 4rem;
  padding: 0;
}

.calculator-content .left .section .questions > li {
  margin: 3.6rem 0 0 0;
}

.calculator-content .left .section .questions > li:first-child {
  margin-top: 0.4rem;
}

.calculator-content .left .section .questions > li > p {
  font-size: 1.4rem;
  margin: 0 0 1.6rem 0;
}

.calculator-content .left .section .questions > li > p:first-child {
  margin-top: 1.6rem;
  font-weight: 500;
}

.calculator-content .left .section .questions .answers {
  list-style: none;
  margin: 0;
  padding: 0;
}

.calculator-content .left .section .questions .answers > li {
  display: inline;
  margin-right: 3.5rem;
  font-size: 1.4rem;
  font-weight: 500;
}

.calculator-content .left .section .questions .answers > li input[type='radio'] {
  margin-right: 0.8rem;
}

.calculator-content .left .section .questions .answers > li input[type='radio']:checked + label {
  color: #61a601;
}

.calculator-content .right {
  flex-grow: 0;
  flex-shrink: 0;
  width: 22rem;
  margin-left: 3.6rem;
}

.calculator-content .right .box-container {
  width: 100%;
  padding: 0;

  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 14rem;
}

.calculator-content .right .box {
  width: 100%;
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.09);
  background: #ffffff;
  padding: 0;
  overflow: auto;
}

.calculator-content .right .box h3 {
  font-size: 1.4rem;
  font-weight: normal;
  color: #767676;
  margin: 1.7rem 2.4rem 1.7rem 2.4rem;
  text-align: center;
}

.calculator-content .right .box p {
  font-size: 1.4rem;
  font-weight: normal;
  text-align: right;
  color: #767676;
  margin: 0 2.4rem 1.2rem 2.4rem;
  text-transform: uppercase;
}

.calculator-content .right .box .value {
  color: #ffffff;
  background-color: #61a601;
  font-size: 1.6rem;
  font-weight: bold;
  text-align: right;
  text-shadow: 0 0 1.35rem rgba(0, 0, 0, 0.29);
  padding: 0 2.4rem 0 2.4rem;
  margin: 0;
  height: 4.3rem;
  line-height: 4.3rem;
  display: inline-block;
  width: 100%;
}

.calculator-content .left .extra {
  margin-bottom: 6rem;
}

.calculator-content .left .extra h2 {
  font-size: 2.1rem;
  font-weight: normal;
  color: #767676;
  margin: 8.4rem 0 3.6rem 0;
}

.calculator-content .left .extra .extra-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.calculator-content .left .extra .extra-content > div {
  margin-right: 4rem;
  cursor: pointer;
}

.calculator-content .left .extra .extra-content > div:last-child {
  margin-right: 0;
}

.calculator-content .left .extra .extra-content .buttons {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 13.5rem;
  justify-content: center;
}

.button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #000000;
  outline: none;
  height: 4.0rem;
  line-height: 4.0rem;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.4rem;
  border: none;
  vert-align: middle; /* ??? */
  width: 22rem;
  padding: 0;
}

.button:active {
  background-color: #444444;
}

.button.green {
  background-color: #61a601;
}

.button.green:active {
  background-color: #7bc711
}

.button:disabled {
  opacity: 0.4;
}

.button.add-more {
  width: 30rem;
}

.extra-content .offset-adjustment {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 12rem;
  position: relative;
}

.extra-content .offset-adjustment .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.8rem;
}

.extra-content .offset-adjustment .icon img {
  height: 1.6rem;
}

.extra-content .offset-adjustment .values {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 1.4rem 0 rgba(0, 0, 0, 0.09);
  background: #ffffff;
}

.extra-content .offset-adjustment .values .percentage {
  height: 6.4rem;
  line-height: 6.4rem;
  font-size: 2.3rem;
  font-weight: bold;
  text-align: center;
  vertical-align: center;
  color: #141b28;
}

.extra-content .offset-adjustment.custom .values .percentage {
  font-style: italic;
  color: #aaaaaa;
}

.extra-content .offset-adjustment .values .custom-percentage {
  position: relative;
  z-index: 1000;
  height: 6.4rem;
  line-height: 6.4rem;
  font-family: ClanProWide, sans-serif;
  font-size: 2.3rem;
  font-weight: bold;
  text-align: center;
  color: #141b28;
  width: 100%;
  outline: none;
  border: none;
}

.extra-content .offset-adjustment .values .custom-percentage::placeholder {
  font-style: italic;
  color: #aaaaaa;
}

.extra-content .offset-adjustment .values .custom-percentage-sign {
  position: absolute;
  top: 2.4rem;
  right: 0.7rem;
  z-index: 1001;
  height: 6.4rem;
  line-height: 6.4rem;
  font-family: ClanProWide, sans-serif;
  font-size: 2.3rem;
  font-weight: bold;
  text-align: center;
  color: #141b28;
  pointer-events: none;
}

.extra-content .offset-adjustment .values .custom-percentage:placeholder-shown + .custom-percentage-sign {
  visibility: hidden;
}

.extra-content .offset-adjustment .values .count {
  height: 2.6rem;
  line-height: 2.6rem;
  font-size: 1.2rem;
  font-weight: normal;
  text-align: center;
  vertical-align: center;
  background: #141b28;
  color: #ffffff;
}

.extra-content .offset-adjustment p {
  margin: 1.2rem 0 0 0;
  color: #141b28;
  font-size: 1.2rem;
  text-align: center;
  text-transform: uppercase;
}

.extra-content .offset-adjustment.selected:after {
  content: '';
  position: absolute;
  top: 1rem;
  right: -1rem;
  bottom: -1rem;
  left: -1rem;
  border: 0.2rem solid #61a601;
}

.tip {
  display: flex;
  flex-direction: row;
  border-bottom: 0.4rem solid #61a601;
  align-items: flex-start;
  background-image: linear-gradient(to top, #f9f9f9, #ffffff);
}

.tip .sign {
  flex-grow: 0;
  font-weight: 500;
  font-size: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #61a601;
  padding: 4.2rem 3.2rem 3.2rem 3.2rem;
}

.tip .text {
  flex-grow: 1;
  font-size: 1.3rem;
  color: #434248;
  text-align: justify;
  padding: 3.2rem 3.2rem 3.2rem 0;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #ffffff;
  border: 0.1rem solid #dddddd;
  color: #141b28;
  width: 100%;
  max-width: 30rem;
  padding: 1rem 2rem 1rem 1rem;
  font-family: ClanProWide, sans-serif;
  font-size: 1.4rem;
  border-radius: 0.6rem;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-position-x: calc(100% - 0.8rem);
  background-position-y: center;
  background-repeat: no-repeat;
  outline: none;

  transition: all;
  transition-duration: 0.3s;
}

input[type="number"], input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #ffffff;
  border: 0.1rem solid #dddddd;
  color: #141b28;
  width: 100%;
  max-width: 30rem;
  padding: 1rem;
  font-family: ClanProWide, sans-serif;
  font-size: 1.4rem;
  border-radius: 0.6rem;
  position: relative;
  outline: none;

  transition: all;
  transition-duration: 0.3s;
}

input[type="number"].small, input[type="text"].small, select.small {
  max-width: 10rem;
}

input[type="number"]:focus, input[type="text"]:focus, select:focus {
  border-color: #61a601;
}

ul.attributes {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.attributes > li {
  display: flex;
  align-items: center;
  margin-bottom: 0.6rem;
}

ul.attributes > li:last-child {
  margin-bottom: 0;
}

ul.attributes > li label {
  flex-grow: 1;
  margin: 0;
  padding: 0;
}

ul.attributes > li input, ul.attributes > li span {
  flex-grow: 0;
  margin-left: 1.6rem;
}

ul.attributes > li span {
  width: 4rem;
}

ul.attributes > li span.wide {
  width: 8rem;
}

.delete-button {
  float: right;
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
  object-fit: contain;
}

.delete-button:active {
  opacity: 0.4;
}

.disclaimer {
  margin-bottom: 5rem;
  font-size: 1.1rem;
  text-align: justify;
  line-height: 150%;
}

.disclaimer .separator {
  width: 100%;
  height: 0.2rem;
  background: #141b28;
  display: block;
  margin-bottom: 2.4rem;
}

.send-result-button {
  margin-top: 1.6rem;
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.09);
}

.send-to-myself {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.send-to-myself .label {
  margin-bottom: 8rem;
}

.send-to-myself input[type="text"] {
  flex-grow: 1;
  max-width: 100%;
}

.send-to-myself #recaptcha-container {
  flex-grow: 0;
  margin-left: 1.6rem;
}

.send-to-myself button {
  flex-grow: 0;
  margin-left: 1.6rem;
}

@media screen and (max-width: 800px) {
  .calculator-content {
    margin: 0 2rem 0 2rem;
    flex-direction: column-reverse;
  }

  .answers li {
    display: block !important;
  }

  .tip {
    flex-direction: column;
  }

  .tip .sign {
    padding-bottom: 0 !important;
  }

  .tip .text {
    padding-left: 3.2rem;
  }

  .extra-content {
    flex-direction: column !important;
    align-items: center !important;
  }

  .extra-content .offset-adjustment {
    margin-right: 0 !important;
    margin-bottom: 4rem !important;
  }

  .extra h2 {
    text-align: center;
  }

  .calculator-header {
    margin: 0 4rem 2rem 4rem;
  }

  .calculator-header h2 {
    text-align: center;
  }

  .right {
    margin: 0 4rem 4rem 4rem !important;
    width: 100% !important;
  }

  .right {
    position: fixed !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .right .box-container {
    background: #ffffff;
  }

  .right .box-container .box .value {
    width: calc(100% - 3.2rem);
    margin: 0 1.6rem 0 1.6rem;
  }

  .right .box-container .button {
    width: calc(100% - 3.2rem);
    margin: 1.6rem 1.6rem 1.6rem 1.6rem;
  }

  .right .box p, .right .box span {
    text-align: center !important;
  }

  select, input[type="text"], input[type="number"] {
    max-width: 100%;
  }

  .disclaimer {
    font-size: 1.1rem;
  }

  .send-to-myself {
    flex-direction: column;
  }

  .send-to-myself #recaptcha-container, .send-to-myself button {
    margin-left: 0;
    margin-top: 1.6rem;
    max-width: 100%;
  }
}

</style>