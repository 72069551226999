


let paths = {
    data() {
        return {
            assets: {
                images: {
                    
                    
                    'buyer.svg': '/assets/buyer-97aacc25b66257c5581a3dd92daa12f73c2a2c4dd74cd5f4c3ee4b886a45e86b.svg',
                    
                    'icon-crown.png': '/assets/icon-crown-579cb1bf4d0d06541f773c5d7f9f1f79713a772d69d9178e66be6376c5d81424.png',
                    
                    'icon-tickmark.png': '/assets/icon-tickmark-3cad98816b7839152f66229d4ad57caaa501005f2e5d72293bffe8ff27bbc8f0.png',
                    
                    'offset-increased.jpg': '/assets/offset-increased-7aa70d1a933f493bccdb00d065096444b5d50433317417ee9a9ac88fa0c347a0.jpg',
                    
                    'private.svg': '/assets/private-ee6eb1a420fc8bd1f5a1c9af22fc6e7cc60b289bd04e3275db36179725d1b1da.svg',
                    
                    'trash-can-solid.svg': '/assets/trash-can-solid-21d0a69c716c083b5c3489161156d4c306b1a6af5c9b4cc01b44444686696772.svg',
                    
                    'icon-pencil.png': '/assets/icon-pencil-83289f0d71db43f38e9d3d1d01dfaeb0657c2b7fc2f976f4c90ff23e692709b6.png',
                    
                    'placeholder.svg': '/assets/placeholder-6a0d9b0c590f29da702f54989ca71f2d9d0e3927a94b671df5188f819257fa08.svg',
                    
                    'simplepay.png': '/assets/simplepay-60a051470ccb87229abf94324c9f164b6165c506e7ba94c8b26a687a6eaedf20.png',
                    
                    'registration.jpg': '/assets/registration-9d7b612b7127141bb480ec5fa738aa4b19ed87e3e467ad3e4806b52a1f4b1db2.jpg',
                    
                    'icon-badge.png': '/assets/icon-badge-043d3393f0ea81051dda8de8e81c1caf1d68dc928373777a774606649e124604.png',
                    
                    'simplepay@2x.png': '/assets/simplepay@2x-58707efe0a1664a9d43e8e2fd0779b0b879c1ef3971b050964718213a248a39c.png',
                    
                    'b2b.svg': '/assets/b2b-7b7fd458d49001e2ca46cddc7f9ac5cc0e6419b670817fffbd79ff999805327e.svg',
                    
                    'eron-logo.png': '/assets/eron-logo-e8f26c10891a913631e358d5caed383837f4b5037c82e7909f5cf709221c3508.png',
                    
                    'normal.svg': '/assets/normal-c115b26a1878f56d9519ab70c0c212fe8d3f3354dfb69d994dcf2721ecb1d25d.svg',
                    
                    'offset-double.jpg': '/assets/offset-double-45c4387ec98fff57de0d5c1a53fc4c04146ebfb19c1bc0b04fa7a9360e756b46.jpg',
                    
                    'checkout.png': '/assets/checkout-d65b6018981cecc1772c936694a8162f2772c2242be5d19fdc27222ee4b166b6.png',
                    
                    'tree.svg': '/assets/tree-d7d2d398942ef9f50b94841971d73c3deeeacac8626da96e92b0ee8ce841ce2f.svg',
                    
                    'icon-location.png': '/assets/icon-location-bd8bf0b5e87ac11e85a171d09eb322b4b449395559b30706e0a2552e203a6515.png',
                    
                    'registration@2x.jpg': '/assets/registration@2x-9bfd05dfcea52955070a217d49ba165b0e7cf06c15f3a8a15139eb7a678f7c26.jpg',
                    
                    'offset-full.jpg': '/assets/offset-full-164f03981f39f46d51d44551d9f83391ecb563e58770f30ea7607a704be8daa5.jpg',
                    
                    'angle-down-solid.svg': '/assets/angle-down-solid-7c46f2511c3ba6b880aced2d4cfbd077285a84db75f5820353a312e9396d3457.svg',
                    
                    'government.svg': '/assets/government-0dd42bb957602ea2fd375c86c3f8375e5e0cfb0a295831786de6c23bd3311d78.svg',
                    
                    'checkout.webp': '/assets/checkout-d3f647ed1ac8366ec3af4b0c40303090aacb9992d35c8cd0aa16a5d68e97d815.webp',
                    
                    'registration.svg': '/assets/registration-ddfc0d471d44d4fbb27cc7869daa17c9272fa2d524ad9c5c04bea2c702bec59a.svg',
                    
                    'seller.svg': '/assets/seller-0e4966e9266bddc099b9f1871bb6bb83489510e8dbf2048df41610fdd0687763.svg',
                    
                    'angle-up-solid.svg': '/assets/angle-up-solid-449da7fa765d1cf6fb17878bc6141f7dbbbe6c3f8cf841e0fdcd295e1105c605.svg',
                    
                    'add-tree-icon.svg': '/assets/add-tree-icon-ba23cb2ee0687dccef83c15ef307913325face6e0a1eb8404ca9a2d16ff26e13.svg',
                    
                    'registration@2x.webp': '/assets/registration@2x-08541b4ff30b44858cfcfb8e9a9bc437093f47f59930d06ee9a1d59188694551.webp',
                    
                    'registration.webp': '/assets/registration-f98fd6b04886dbd749c7c1f13b2e469cf30de01c5dcd3ac643f49ce27e38ec10.webp',
                    
                }
            }
        }
    },
    methods: {
        imagePath(name) {
            return this.assets.images[name];
        },
        shopPath(format = 'html') {
            if (format === 'json') {
                return '/shop.json';
            } else {
                return '/shop';
            }
        },
        shopAddressesPath(format = 'html') {
            if (format === 'json') {
                return '/shop/addresses.json';
            } else {
                return '/shop/addresses';
            }
        },
        shopSaveAddressPath(format = 'html') {
            if (format === 'json') {
                return '/shop/addresses/save.json';
            } else {
                return '/shop/addresses/save';
            }
        },
        productCategoriesPath() {
            return '/product_categories.json';
        },
        shopSubmitPath() {
            return '/shop/pay';
        },
        shopSaveCartAndSignInPath() {
            return '/shop/save_cart_and_sign_in';
        },
        buildCartPath() {
            return '/shop/build_cart'
        },
        shopConfigurationPath() {
            return '/shop/get-configuration.json';
        },
        saveCalculatorResultsPath() {
            return '/karbonlabnyom-kalkulator/gyors.json';
        },
        profileCountriesPath() {
            return '/profile/countries.json';
        },
    }
};

export default paths;