window.addEventListener('DOMContentLoaded', () => {
    const detectionDiv = document.querySelector('#enable-force-dark-detection');
    const isAutoDark = getComputedStyle(detectionDiv).backgroundColor !== 'rgb(255, 255, 255)';

    if (isAutoDark) {
        const style = document.createElement('style');
        style.innerHTML = `
            .shop-counter .label { color: #64a70b !important }
            section.about::before { background: none !important }
            section.calculator .bg { display: none }
            section.projects { background: none !important }
            section.did-you-know { background: none !important }
            section.did-you-know-page { background: none !important }
            section.projects-page .details { background: none !important }
            section.flyer-page { background: none !important }
            section.calculator-header { background: none !important }
            section.legacy-page-bg { background: none !important }
            div.shop-app-container { background: none !important }
        `;
        document.head.appendChild(style);
    }
});
