window.addEventListener('DOMContentLoaded', () => {
    const backToTopButton = document.getElementById("back-to-top");
    window.onscroll = function () {
        scrollFunction();
    };

    function scrollFunction() {
        if (backToTopButton != null) {
            if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
                backToTopButton.style.display = "block";
            } else {
                backToTopButton.style.display = "none";
            }
        }
    }

    scrollFunction();
});

function scrollToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}
