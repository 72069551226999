<template>
  <div :id="id" class="modal fade show" tabindex="-1" :aria-labelledby="id + '-title'" aria-modal="true" role="dialog" :aria-hidden="!isVisible">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded shadow border-0">
        <div class="modal-header border-bottom">
          <h5 class="modal-title" :id="id + '-title'">{{ title }}</h5>
          <button type="button" class="btn-close" aria-label="Mégsem" @click="hide()"></button>
        </div>
        <div class="modal-body" :class="{ 'dialog-disabled': isLoading }">
          <div class="bg-white p-3 rounded box-shadow">
            <slot></slot>
          </div>
        </div>
        <div class="modal-footer">
          <button v-bind:class="button.type" v-for="button in buttons" v-on:click="button.onClick" :disabled="isLoading">{{ button.title }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "dialog-box",
  props: {
    id: String,
    title: String,
    buttons: Array,
    isVisible: Boolean,
    isLoading: Boolean,
    onHide: Function
  },
  watch: {
    isVisible: {
      handler: function (newValue, oldValue) {
        $('#' + this.id).modal(this.isVisible ? 'show' : 'hide');
      }
    }
  },
  methods: {
    hide() {
      this.onHide();
    }
  },
  mounted() {
    $('#' + this.id).on('hide.bs.modal', () => {
      this.isVisible = false;
    });
  }
}
</script>

<style scoped>

.dialog-disabled {
  opacity: 0.4;
  pointer-events: none;
}

</style>